import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://110709bec539cf73e875b572a4bc4a61@o4504326775701504.ingest.us.sentry.io/4508330538958848",
  environment: process.env.VERCEL_ENV,
  release: process.env.VERCEL_DEPLOYMENT_ID,
  enabled: process.env.VERCEL_ENV === "production",
  tracesSampleRate: 0.2,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: [
    /(?:https?:\/\/)?[^\/]*\.reshift\.nl/, // match ex. api.reshift.nl
    /https:\/\/api\./, // match ex. api.kieskeurig.nl
    /(?:https?:\/\/)?[^\/]+\.[a-z]{2,}(?=\/api\b)/, // match kieskeurig.nl/api/products/products
  ],
});
